import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
  orders;
  constructor(
    private data: DataService,
    private auth: AngularFireAuth) { }

  ngOnInit(): void {
    this.orders = this.data.getAllOrders();
  }

  onDelete(id) {
    if (confirm("Czy na pewno chcesz usunąć zamówienie "+id+"?")) {
      this.data.deleteOrder(id);
    }
  }
}