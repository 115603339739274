// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCUAYcmaNoK-VyBnDtJDKBNjGdd_3KEJXw",
    authDomain: "test-project-d3d86.firebaseapp.com",
    databaseURL: "https://test-project-d3d86.firebaseio.com",
    projectId: "test-project-d3d86",
    storageBucket: "test-project-d3d86.appspot.com",
    messagingSenderId: "862156870424",
    appId: "1:862156870424:web:6f3aa82aa0dfe03b6d446d",
    measurementId: "184868521"
  },
  settings: {
    base_url: "curryup.pl",
    name: "Curry up!",
    title: "Curry up!",
    client: "curry",
    logo: "https://curryup.pl/wp-content/uploads/2015/03/logo_curry_frame.png",
    opening_time: 0,
    closing_time: 0,
    phone: "601757185"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
