import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.css']
})
export class DisplayComponent implements OnInit {
  id: string;
  public order$: Observable<any>;
  public items$: Observable<any>;
  public settings;

  constructor(private data: DataService, private route: ActivatedRoute) {
    this.settings = environment.settings;
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params.id;
      if (this.id) {
        console.log(this.id);
        this.order$ = this.data.getOrderById(this.id);
        this.items$ = this.data.getCartItemsById(this.id);
        this.data.checkStatus(this.id);
      }
    });
  }
  onCheckStatus() {
    this.data.checkStatus(this.id);
  }
}