import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../data.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { take } from 'rxjs/operators';
import * as firebase from 'firebase';


let that: PayComponent;

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.css']
})
export class PayComponent implements OnInit {
  public items$;
  public order$;
  public address: string;
  private total: number;
  order: any = {};

  constructor(private data: DataService,
    private firestore: AngularFirestore,
    private cdr: ChangeDetectorRef) {
    that = this;
  }

  ngOnInit() {
    if (this.data.orderId) {
      this.items$ = this.data.getCartItems();
      this.order$ = this.data.getOrder();
    } else {
      this.data.toggle.subscribe((result) => {
        this.items$ = this.data.getCartItems();
        this.order$ = this.data.getOrder();
        this.cdr.detectChanges();
      });
    }
    //this.data.setShippingMethod('odbior');

  }

  do(event) {
    let windowReference = window.open();
    event.target.innerHTML = '<i class="fas fa-spinner fa-pulse"></i>';
    event.target.disabled = true;
    this.order$.pipe(take(1)).subscribe((res) => {
      console.log(res);
      this.data.makePayment(this.data.orderId, (res['total'] + res['shipping_cost']) * 100, this.order.email, this.order.mobile).pipe(take(1))
        .subscribe((response) => {
          console.log(response);
          this.firestore.collection('users').doc(this.data.user.uid).update({ orderId: null });
          console.log(this.order.info);
          let update = {};
          let date = new Date();
          if (this.order.info) {
            update = {
              status: "w trakcie realizacji",
              mobile: this.order.mobile,
              email: this.order.email,
              info: this.order.info,
              time: date.valueOf()
            };
          } else {
            update = {
              status: "w trakcie realizacji",
              mobile: this.order.mobile,
              email: this.order.email,
              time: date.valueOf()
            };
          }
          this.firestore.collection('orders').doc(this.data.orderId).update(update);

          // show info box

          // redirect in new window
          console.log("redirect!!");
          windowReference.location = response['redirectUrl'];
          //window.open(response['redirectUrl'], "_blank");
        });
    });
  }



  ///////////////////////////////// PRZELEWY 24
  /*
  ngOnInit(): void {
    this.generateMd5();
  }
  //public action = "https://sklep.przelewy24.pl/zakup.php";
  public action = "https://sandbox.przelewy24.pl/index.php";
  public p24_sign = 'test';
 
  public p24_session_id = "test"
  public p24_merchant_id = 107025;
  public p24_amount = 1000;
  public p24_currency = "PLN";
  public CRC = "52f56f1b021c9ac8";
 
  generateMd5() {
    const md5 = new Md5();
    this.p24_sign = Md5.hashStr(
      this.p24_session_id + "|" +
      this.p24_merchant_id + "|" +
      this.p24_amount + "|" +
      this.p24_currency + "|" +
      this.CRC
    ).toString();
  }
  */
}
