import { Component, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../data.service';
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment';
import { ChangeDetectionStrategy } from '@angular/core';

let that: CartComponent;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartComponent implements OnInit {
  public order$: Observable<any>;
  public items$: Observable<any>;
  public settings;
  closed;

  constructor(private data: DataService,
    private cdr: ChangeDetectorRef) {
    that = this;
    this.settings = environment.settings;
  }

  ngOnInit() {
    let time = new Date().toString().split(' ')[4];
    let day = new Date().toString().split(' ')[0];
    if ((Number(time.split(':')[0]) >= this.settings.closing_time || Number(time.split(':')[0]) < this.settings.opening_time) || (day == "Sun" || day == "Sat")) {
      this.closed = 1;
    } else {
      this.closed = 0;
    }

    if (this.data.orderId) {
      this.items$ = this.data.getCartItems();
      this.order$ = this.data.getOrder();
    } else {
      this.data.toggle.subscribe((result) => {
        this.items$ = this.data.getCartItems();
        this.order$ = this.data.getOrder();
        this.cdr.detectChanges();
      });
    }
    //this.data.setShippingMethod('odbior');

    /*
    this.auth.onAuthStateChanged(function (user) {
      if (user.uid) {
        that.data.initializeCart();
        console.log(that.data.getCartItems(user.uid));
        that.items = that.data.getCartItems(user.uid);
        console.log(that.data.getOrder(user.uid));
        that.order = that.data.getOrder(user.uid);
      }
    });
    */

  }

  onDelete(item) {
    this.data.deleteFromCart(item);
  }

  onChangeShipping(method) {
    this.data.setShippingMethod(method);
  }

  onClearAddress() {
    this.data.clearAddress();
  }
}