import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {
  public address: string;

  constructor(private data: DataService) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.data.checkAddress(this.address);
  }

}
