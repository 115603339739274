import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../data.service';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';

let that: KoszykComponent;

@Component({
  selector: 'app-koszyk',
  templateUrl: './koszyk.component.html',
  styleUrls: ['./koszyk.component.css']
})
export class KoszykComponent implements OnInit {
  public order$: Observable<any>;
  public items$: Observable<any>;
  public addressData = {};
  settings;
  closed;

  constructor(
    private data: DataService,
    private auth: AngularFireAuth,
    private cdr: ChangeDetectorRef) {
    that = this;
    this.settings = environment.settings;
  }

  ngOnInit() {
    let time = new Date().toString().split(' ')[4];
    let day = new Date().toString().split(' ')[0];
    if ((Number(time.split(':')[0]) >= this.settings.closing_time || Number(time.split(':')[0]) < this.settings.opening_time) || (day == "Sun" || day == "Sat")) {
      this.closed = 1;
    } else {
      this.closed = 0;
    }
    if (this.data.orderId) {
      this.items$ = this.data.getCartItems();
      this.order$ = this.data.getOrder();
    } else {
      this.data.toggle.subscribe((result) => {
        this.items$ = this.data.getCartItems();
        this.order$ = this.data.getOrder();
        this.cdr.detectChanges();
      });
    }
  }

  onClearAddress() {
    this.data.clearAddress();
  }
}