import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private data: DataService, private router: Router) { }

  ngOnInit(): void {
  }
  error;
  public email;
  public password;
  public myForm: NgForm;

  onSubmit(f) {
    this.data.login({ email: f.value.email, password: f.value.password })
      .catch((error) => {
        console.log(error);
        this.error = error.message;
      }).then(() => {
        this.router.navigate(['/admin']);
      });
  }
}