import { NgModule } from "@angular/core";
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from "./home/home.component";
import { CartComponent } from "./cart/cart.component";
import { PayComponent } from "./pay/pay.component";
import { CompleteComponent } from "./complete/complete.component";
import { RouteComponent } from "./route/route.component";
import { OrdersComponent } from "./admin/orders/orders.component";
import { DisplayComponent } from "./display/display.component";
import { InfoComponent } from "./info/info.component";
import { LoginComponent } from "./admin/login/login.component";
import { AngularFireAuthGuard, hasCustomClaim, redirectUnauthorizedTo, redirectLoggedInTo, isNotAnonymous } from '@angular/fire/auth-guard';
import { pipe } from "rxjs";
import { map } from "rxjs/operators";
import { LogoutComponent } from "./admin/logout/logout.component";
import { DashboardComponent } from "./admin/dashboard/dashboard.component";
import { ListComponent } from "./admin/menu/list/list.component";
import { SettingsComponent } from "./admin/settings/settings.component";
import { IntroComponent } from "./intro/intro.component";


export const redirectAnonymousTo = (redirect: any[]) =>
  pipe(isNotAnonymous, map(loggedIn => loggedIn || redirect)
  );

const adminOnly = () => hasCustomClaim('admin');
//const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['admin/login']);
const redirectLoggedInToItems = () => redirectLoggedInTo(['zamowienia']);
const belongsToAccount = (next) => hasCustomClaim(`account-${next.params.id}`);
const redirectUnauthorizedToLogin = () => redirectAnonymousTo(['admin/login']);


const appRoutes: Routes = [
  { path: '', component: IntroComponent, pathMatch: 'full' },
  { path: 'koszyk', component: CartComponent },
  { path: 'zaplac', component: PayComponent },
  { path: 'sukces', component: CompleteComponent },
  { path: 'route', component: RouteComponent },
  { path: 'z/:id', component: DisplayComponent, pathMatch: 'full' },
  { path: 'info', component: InfoComponent },
  { path: 'admin/zamowienia', component: OrdersComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'admin/login', component: LoginComponent },//, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'admin/logout', component: LogoutComponent },
  { path: 'admin/menu', component: ListComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'admin/ustawienia', component: SettingsComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'admin', component: DashboardComponent, pathMatch: 'full', canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}