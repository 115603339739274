import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import 'firebase/firestore';
import { environment } from 'src/environments/environment';
import { DataService } from './data.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  settings;
  title;
  constructor(firestore: AngularFirestore, private data: DataService, private auth: AngularFireAuth) {
    this.settings = environment.settings;
    this.title = this.settings.title;
  }
  closed;
  user;
  showAdmin = false;
  ngOnInit() {
    let time = new Date().toString().split(' ')[4];
    let day = new Date().toString().split(' ')[0];
    if ((Number(time.split(':')[0]) >= this.settings.closing_time || Number(time.split(':')[0]) < this.settings.opening_time) || (day == "Sun" || day == "Sat")) {
      this.closed = 1;
    } else {
      this.closed = 0;
    }
    this.auth.authState.subscribe(user => {
      if (user) {
        this.user = user;
        if (this.user.isAnonymous) {
          this.showAdmin = false;
          console.log("anonymous login");
        } else {
          this.showAdmin = true;
          console.log("user login");
        }
      } else {
        console.log("no login");
      }
    })
  }
}
