import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DataService } from 'src/app/data.service';
import { AngularFirestore } from '@angular/fire/firestore';


@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {
  @Input() item: any;
  constructor(private fb: FormBuilder, private data: DataService, private firestore: AngularFirestore) { }

  editForm = this.fb.group({
    name: [''],
    description: [''],
    price: [''],
    order: [''],
    img: ['']
  });

  ngOnInit(): void {
    if (this.item) {
      this.editForm.get('name').setValue(this.item.name);
      this.editForm.get('description').setValue(this.item.description);
      this.editForm.get('price').setValue(this.item.price);
      this.editForm.get('order').setValue(this.item.order);
      this.editForm.get('img').setValue(this.item.img);
    }
  }

  onSubmit() {
    if (this.item.id) { // edit
      this.data.editMenu(this.item.id, this.editForm.value);
    } else { //add new
      // TODO
    }
  }
}
