import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from "@angular/fire/auth";


import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KoszykComponent } from './koszyk/koszyk.component';
import { environment } from '../environments/environment';
import { CartComponent } from './cart/cart.component';
import { LoaderComponent } from './loader/loader.component';
import { PayComponent } from './pay/pay.component';
import { CompleteComponent } from './complete/complete.component';
import { RouteComponent } from './route/route.component';
import { OrdersComponent } from './admin/orders/orders.component';
import { AddressComponent } from './elements/address/address.component';
import { DisplayComponent } from './display/display.component';
import { InfoComponent } from './info/info.component';
import { APP_BASE_HREF } from '@angular/common';
import { LoginComponent } from './admin/login/login.component';
import { LogoutComponent } from './admin/logout/logout.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { ListComponent } from './admin/menu/list/list.component';
import { SettingsComponent } from './admin/settings/settings.component';
import { EditComponent } from './admin/menu/edit/edit.component';
import { IntroComponent } from './intro/intro.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    KoszykComponent,
    CartComponent,
    LoaderComponent,
    PayComponent,
    CompleteComponent,
    RouteComponent,
    OrdersComponent,
    AddressComponent,
    DisplayComponent,
    InfoComponent,
    LoginComponent,
    LogoutComponent,
    DashboardComponent,
    ListComponent,
    SettingsComponent,
    EditComponent,
    IntroComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    StorageServiceModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireAuthModule
  ],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
