import { Inject, Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
//import { AngularFireAuth } from 'angularfire2/auth';

import 'firebase/firestore';
import { Order } from './order.model';
import * as firebase from 'firebase/app';
import { environment } from 'src/environments/environment';
import { User } from 'firebase';
import { Router } from '@angular/router';
import { auth } from 'firebase/app';

var that: DataService;

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public uid: string;

  orders: AngularFirestoreCollection<Order>;
  orderDoc: AngularFirestoreDocument<Order>;
  order: Observable<any>;
  orderId: any;
  user;
  toggle = new EventEmitter<String>();e

  constructor(
    private http: HttpClient,
    private firestore: AngularFirestore,
    private auth: AngularFireAuth,
    public router: Router) {
    that = this;

    /*
    this.auth.signInAnonymously()
      .then(value => {
      })
      .catch(function (error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorMessage)
      });
    */

    this.auth.onAuthStateChanged(user => {
      if (user) {
        this.user = user;
        this.initializeCart();
      } else {
        this.auth.signInAnonymously();
      }
    });
  }

  initializeCart() {
    if (this.user.uid) {
      if (typeof this.orderId === 'undefined') {
        this.firestore.collection('users').doc(this.user.uid).ref.get()
          .then(
            response => {
              if (response.data()) {
                that.orderId = response.data()['orderId'];
                if (!that.orderId) {
                  that.orderId = this.firestore.createId();
                  this.toggle.emit("test234");
                  this.firestore.collection('users').doc(this.user.uid).update({ orderId: that.orderId });
                  this.firestore.collection('orders').doc(this.orderId).set({ total: 0, count: 0, shipping_method: 'dowoz', shipping_cost: 0, userId: this.user.uid, status: '--' });
                } else {
                  this.toggle.emit("test234");
                  this.firestore.firestore.collection('orders').doc(this.orderId).get()
                    .then(docSnapshot => {
                      if (docSnapshot.exists) {
                      } else {
                        this.firestore.collection('orders').doc(this.orderId).set({ total: 0, count: 0, shipping_method: 'dowoz', shipping_cost: 0, userId: this.user.uid, status: '--' });
                      }
                    })
                    .catch(error => {
                      console.log(error);
                    });
                }
              } else {
                this.firestore.collection('users').doc(this.user.uid).set({ id: this.user.uid }).then(
                  () => this.initializeCart()
                );
              }
              //this.order = this.firestore.collection('orders').doc(this.orderId).valueChanges();
            })
          .catch(
            error => {
              console.log(error);
            });
      }
    } else {
      console.log("User is not logged!");
    }
  }

  checkStatus(id: string) {
    let res = {};

    this.http.get('https://www.' + environment.settings.base_url + '/api/getStatus/' + id) //'https://' + environment.settings.base_url + 
      .pipe(map(
        (response) => {
          return response;
        }
      ))
      .pipe(catchError(
        (error: Response) => {
          console.log(error);
          return throwError(error);
        }
      )).subscribe(
        (response) => {
          let status: string;
          if (response) {
            if (response['status'] == "CONFIRMED") {
              status = 'potwierdzona';
            } else if (response['status'] == "PENDING") {
              status = 'oczekuje';
            } else if (response['status'] == "NEW") {
              status = 'nowa';
            } else if (response['status'] == "ERROR") {
              status = 'błąd płatności';
            } else {
              status = 'brak informacji';
            }
            this.firestore.collection('orders').doc(id).update({ payment_status: status });
          }
        },
        (error) => {
        }
      );
    return res;
  }

  deleteOrder(id) {
    /*
    this.firestore.firestore.collection('orders').doc(id).collection('items').get().then((result) => {
      console.log(result);
    });
    */
    this.firestore.collection('orders').doc(id).delete();
  }

  addToCart(item) {
    this.firestore.collection('orders').doc(this.orderId).collection('items').add({ name: item.name, price: item.price, id: item.id, img: item.img });
    this.firestore.collection('orders').doc(this.orderId).update({ count: firebase.firestore.FieldValue.increment(1), total: firebase.firestore.FieldValue.increment(item.price * 1) });
  }

  deleteFromCart(item) {
    this.firestore.collection('orders').doc(this.orderId).collection('items').doc(item.id).delete();
    this.firestore.collection('orders').doc(this.orderId).update({ count: firebase.firestore.FieldValue.increment(-1), total: firebase.firestore.FieldValue.increment(item.price * -1) });
  }

  getCartItems() {
    //console.log("getCartItems: " + this.orderId);
    if (this.orderId) {
      //return this.firestore.firestore.collection('orders').doc(this.orderId).collection('items').get();
      return this.firestore.collection('orders').doc(this.orderId).collection('items').valueChanges({ idField: 'id' });
    } else {
      let tmp;
      this.firestore.collection('users').doc(this.user.uid).ref.get()
        .then(
          async response => {
            let orderId = response.data()['orderId'];
            //console.log(orderId);
            tmp = await this.firestore.collection('orders').doc(orderId).collection('items').valueChanges({ idField: 'id' });
          });
      return tmp;
    }
  }


  getOrder() {
    //console.log("getOrder: " + this.orderId);
    if (this.orderId) {
      return this.firestore.collection('orders').doc(this.orderId).valueChanges();
    } else {
      let tmp;
      this.firestore.collection('users').doc(this.user.uid).ref.get()
        .then(
          response => {
            let orderId = response.data()['orderId'];
            tmp = that.firestore.collection('orders').doc(orderId).valueChanges();
          });
      return tmp;
    }
  }

  getCartItemsById(id) {
    return this.firestore.collection('orders').doc(id).collection('items').valueChanges({ idField: 'id' });
  }

  getOrderById(id) {
    return this.firestore.collection('orders').doc(id).valueChanges();
  }

  getAllOrders(): any {
    return this.firestore.collection('orders').valueChanges({ idField: 'id' });
  }

  setShippingMethod(method) {
    let update;
    if (method == 'odbior') {
      update = {
        address: null,
        shipping_method: 'odbior',
        shipping_cost: 0
      }
    } else {
      update = {
        address: null,
        shipping_method: 'dowoz',
        shipping_cost: null
      }
    }
    this.firestore.collection('orders').doc(this.orderId).update(update);
  }

  changeMenuItemActive(mode, id) {
    let data = {};
    if (mode == 'off') {
      data = { active: false };
    } else if (mode == 'on') {
      data = { active: true };
    } else {
      return false;
    }
    if (id) {
      this.firestore.collection('menu').doc(id).update(data);
    }
  }

  clearAddress() {
    this.firestore.collection('orders').doc(this.orderId).update({ address: null, shipping_cost: null, shipping_method: 'dowoz', total_time: 0 });
  }

  editMenu(id, data) {
    this.firestore.collection('menu').doc(id).update(data);
  }

  makePayment(id, amount, email, mobile) {
    return this.http.post('https://www.' + environment.settings.base_url + '/api/payment', { id: id, amount: amount, email: email, mobile: mobile, notify_mobile: environment.settings.phone })
      .pipe(map(
        (response) => {
          console.log(response);
          return response;
        }
      ))
      .pipe(catchError(
        (error: Response) => {
          //console.log(error);
          return throwError(error);
        }
      ));
  }

  makePayment2(id, amount, email, mobile) {
    let res = {};
    this.makePayment(id, amount, email, mobile)
      .subscribe(
        (response) => {
          res['redirectUrl'] = response['redirectUrl'];
          res['status'] = response['status'];
          res['paymentId'] = response['paymentId'];
        },
        (error) => {
        }
      );
    return res;
  }

  getRoute(destination, origin) {
    //return this.http.get('https://maps.googleapis.com/maps/api/directions/json?origin=' + origin + '&destination=' + destination + '&key=AIzaSyBT4nTQxb4CLie3XAeu-oWaeNGk12qyel0&mode=walking&departure_time=now')
    return this.http.get('https://www.' + environment.settings.base_url + '/api/getRoute?mode=bicycling&address=' + destination) //'https://www.' + environment.settings.base_url + 
      .pipe(map(
        (response) => {
          console.log(response);
          return response;
        }
      ))
      .pipe(catchError(
        (error: Response) => {
          console.log(error);
          return throwError(error);
        }
      ));
  }

  checkAddress(address) {
    let order = {};
    this.getRoute("Kraków" + address, "Kraków, Wawrzyńca 16")
      .subscribe(
        (response) => {
          console.log(response);
          /* google api
          order['distance'] = response['routes']['0']['legs']['0']['distance']['text'];
          order['address'] = response['routes']['0']['legs']['0']['end_address'];
          if (response['routes']['0']['legs']['0']['distance']['value'] > 0) {
            order['travel_time'] = response['routes']['0']['legs']['0']['duration']['value'] / 60;
            order['total_time'] = Math.ceil(((order['travel_time'] * 1.33 + 15) / 5)) * 5;
            if (order['total_time'] > 120) {
              order['total_time'] = -1;
            }
          } */
          if (response['address'] == "Kraków, Poland") {
            console.log(response['address']);
          }
          order['address'] = response['address'];
          order['duration'] = response['duration'] / 60;
          //order['duration'] = Math.ceil(((response['duration'] * 1.33 + 15) / 5)) * 5;
          order['distance'] = response['distance'];
          order['total_time'] = Math.ceil(((response['duration'] / 60 * 1.33 + 30) / 5)) * 5;
          if (order['duration'] < 5) {
            order['shipping_cost'] = 4;
          } else if (order['duration'] < 7) {
            order['shipping_cost'] = 5;
          } else if (order['duration'] < 9) {
            order['shipping_cost'] = 6;
          } else if (order['duration'] < 11) {
            order['shipping_cost'] = 7;
          } else if (order['duration'] < 13) {
            order['shipping_cost'] = 8;
          } else if (order['duration'] < 15) {
            order['shipping_cost'] = 9;
          } else if (order['duration'] < 17) {
            order['shipping_cost'] = 10;
          } else if (order['duration'] < 20) {
            order['shipping_cost'] = 11;
          } else {
            order['total_time'] = -1;
          }
          order['shipping_method'] = 'dowoz';
          order['version'] = "1.0.20210423";
          this.firestore.collection('orders').doc(that.orderId).update(order);
        },

        /*
          (response) => {
          order['distance'] = response['routes']['0']['legs']['0']['distance']['text'];
          order['address'] = response['routes']['0']['legs']['0']['end_address'];
          if (response['routes']['0']['legs']['0']['distance']['value'] > 0) {
            order['travel_time'] = response['routes']['0']['legs']['0']['duration']['value'] / 60;
            order['total_time'] = Math.ceil(((order['travel_time'] * 1.33 + 15) / 5)) * 5;
            if (order['total_time'] > 120) {
              order['total_time'] = -1;
            }
          }
          this.firestore.collection('orders').doc(uid).update(order);
        },        
        */

        (error) => {
        }
      );
    return order;
  }

  /* AUTH */
  readonly authState$: Observable<User | null> = this.auth.authState;

  get authUser() {
    return this.auth.currentUser;
  }

  async login({ email, password }) {
    return await this.auth.signInWithEmailAndPassword(email, password);
  }

  async register(email: string, password: string) {
    var result = await this.auth.createUserWithEmailAndPassword(email, password)
  }

  logout() {
    return this.auth.signOut();
  }

  isAuthenticated() {
  }

}
