import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../data.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChild('form') ngForm: NgForm;

  response;

  menu: Observable<any[]>;
  settings;

  constructor(private data: DataService,
    private firestore: AngularFirestore) {
    this.menu = this.firestore.collection('menu', ref => ref.where('client', '==', environment.settings.client).where('active', '==', true).orderBy('order')).valueChanges({ idField: 'id' });
    this.settings = environment.settings;
  }

  ngOnInit() {

  }

  onAddToCart(id) {
    this.data.addToCart(id);
  }
}