import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-route',
  templateUrl: './route.component.html',
  styleUrls: ['./route.component.css']
})
export class RouteComponent implements OnInit {
  _body;

  constructor(private data: DataService) { }

  ngOnInit(): void {
    this.data.checkAddress('starowislna 10');

    this._body = 'test';
    return this.json();
  }

  json(): any {
    if (typeof this._body === 'string') {
      return JSON.parse(<string>this._body);
    }

    return this._body;
  }

}
