import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  menu: Observable<any[]>;
  settings: any;
  environment: any;

  constructor(private data: DataService,
    private firestore: AngularFirestore) {
    this.menu = this.firestore.collection('menu', ref => ref.where('client', '==', environment.settings.client).orderBy('order')).valueChanges({ idField: 'id' });
    this.settings = environment.settings;
  }

  ngOnInit(): void {
  }

  onChangeActive(mode, id) {
    this.data.changeMenuItemActive(mode, id);
  }

}
